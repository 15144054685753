<script setup>
    import Layout from "@/layouts/main.vue";
    import PageHeader from "@/components/page-header";  
    import { required, helpers } from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import { computed, ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import Multiselect from '@suadelabs/vue3-multiselect';
    import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";

    const state = ref({
        id: '',
        name: '',
        logo: '',
        description: '',
        status: true,
        lgus: []
    });

    const rules = {
        name: {
            required: helpers.withMessage("Courier Name is required", required)
        },
        status: {
            required: helpers.withMessage("Courier Status is required", required)
        },
        lgus: {
            required: helpers.withMessage('Select at least one LGU', required)
        }
    };

    const store = useStore();
    const v$ = useVuelidate(rules, state);
    const lguOptions = computed(() => store ? store.state.lgu.dropdownLGUs : []);

    const title = ref("Couriers");
    const items = ref([
        {
            text: "Home",
            href: "/",
        },
        {
            text: "Couriers",
            href: "/delivery/couriers/list",
            active: true
        },
        {
            text: "Add New Courier",
            active: true,
        }
    ]);

    const submitted = ref(false);
    const loading = computed(() => store.state.globals.loading);
    const notification = computed(() => store ? store.state.notification : null);

    const file = ref(null);
    const handleFileUpload = () => {
        state.value.logo = file.value.files[0];
    }

    const createCourier = () => {
        submitted.value = true
        v$.value.$touch();

        if (!file.value.value) {
            delete state.value.logo;
        }

        if (v$.value.$invalid) return;
        store.dispatch('courier/createCourier', state.value);
    }

    onMounted(() => {
        store.dispatch('lgu/getDropdownLGUs')
    })
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col lg-12">
            <b-overlay :show="loading">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title mb-0">Add New Courier</h4>
                    </div>
                    <div class="card-body">
                        <div v-if="notification && notification.messages.length > 0">
                            <b-alert v-for="(message, index) in notification.messages" :key="index"
                                show variant="danger" class="fade show" role="alert">
                                {{ message }}
                            </b-alert>
                        </div>

                        <form enctype="multipart/form-data" @submit.prevent="createCourier" class="mx-2">
                            <div class="row">
                                <p class="fs-6 text text-decoration-underline">Courier Information</p>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="logoFile" class="form-label">Logo</label>
                                        <input ref="file" @change="handleFileUpload" class="form-control" type="file" id="logoFile">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="name" class="form-label required">Name</label>
                                        <input 
                                            autofocus
                                            :class="{ 'is-invalid': submitted && v$.name.$error }"
                                            type="text" class="form-control" id="name" v-model="state.name">
                                        <div
                                            v-for="item of v$.name.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="lgus" class="form-label required">LGU</label>
                                        <multiselect
                                            id="lgus"
                                            :class="{ 'is-invalid': submitted && v$.lgus.$error }"
                                            :multiple="true"
                                            v-model="state.lgus"
                                            :close-on-select="true"
                                            :options="lguOptions"
                                            placeholder="Select LGU(s)"
                                            label="name"
                                            track-by="name"
                                        />
                                        <div
                                            v-for="item of v$.lgus.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="item.$message">{{ item.$message }}</span>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="description" class="form-label">Description</label>
                                        <textarea
                                            class="form-control" id="descrition" v-model="state.description"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                   <div class="mb-3">
                                        <label for="courier-status" class="form-label">Status</label>
                                        <div class="form-check form-switch form-switch-lg" dir="ltr">
                                            <input type="checkbox" name="status" v-model="state.status" class="form-check-input" id="courier-status" checked="">
                                            <label class="form-check-label" for="customSwitchsizelg">{{ state.status ? 'Enabled' : 'Disabled' }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-end">
                                        <router-link to="/delivery/couriers/list" type="submit" class="btn btn-danger mr-3">
                                            <i class="ri-close-fill fs-4 mr-1 valign-middle"></i>
                                            Cancel
                                        </router-link>
                                        <button type="submit" class="btn btn-success">
                                            <i class="ri-save-2-fill fs-4 mr-1 valign-middle"></i>
                                            Add Courier
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
  </Layout>
</template>